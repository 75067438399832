<template>
  <!-- 新闻详情页面 -->
  <div class="index">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />

    <div class="head">
      <p>当前位置：<span style="color: #777777"><a href="/news">新闻资讯</a> > 业内动态</span></p>
    </div>
    <div class="content">
      <div class="leftBox">
        <div class="left">
          <div class="title">
            <p>{{ data.title }}</p>
          </div>

          <!-- 文章内容 -->
          <p class="wear" v-html="removeHtmlStyle(data.content)"></p>
          <hr />
          <div class="remarks">
            <span class="remarksBox"
              ><img
                src="../imger/litterLogo.png"
                class="litterLogo"
                :alt="data.author"
              />作者：{{ data.author }}</span
            >
            <span>浏览：{{ data.click }}人</span>
            <span>发布时间：{{ data.create_time.slice(0, 10) }}</span>
            <div class="redian">#热点资讯</div>
          </div>
        </div>
        <div class="next">
          <div class="un" v-show="downname==undefined? false:true"  @click="down(downname.id)">
            <p class="boxun">
              <img src="../imger/come.png" alt="问标网" class="comeun" />
            </p>
            <p><span>下一篇：</span><span>{{downname==undefined? '':downname.name}}</span></p>
          </div>
          <div class="up" v-show="upname==undefined? false:true" @click="down(upname.id)">
            <p class="boxun">
              <img src="../imger/come.png" alt="问标网" class="comeup" />
            </p>
            <p><span>上一篇：</span><span>{{upname==undefined? '':upname.name}}</span></p>
          </div>
        </div>
      </div>
      <div class="right">
        <Phone />
        <Tuijian />
        <Tuijian02 />
      </div>
    </div>

    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'

import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";

import Phone from "../components/newDetail/phone.vue";
import Tuijian from "../components/newDetail/tuijian.vue";
import Tuijian02 from "../components/newDetail/tuijian02.vue";
import { oneNew } from "../API/new";
import { newIP, keyCode } from "../API/index";
export default {
  inject:['reload'],
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    TradHeader,
    Phone,
    Tuijian,
    Tuijian02,
  },
  data() {
    return {
      data: "",
      ip: "",
      upname:'',
      downname:''

    };
  },
  async created() {
    var keys = generatekey();
    const index = window.location.pathname.indexOf('.html')
    const idpass = window.location.pathname.slice(9,index)
    
    var dess = JSON.parse(decryptDes(idpass,keys));
    await this.keyCode();
    await this.oneNew(dess.newId);
  },
  methods: {
    removeHtmlStyle(html) {
      let rel = /\<img/gi;
      let newHtml = "";
      if (html) {
        newHtml = html.replace(rel, '<img style="width:100%"');
      }
      return newHtml;
    },
    // 获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      let duan = sessionStorage.getItem("key");
      if (duan == null) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },

    async oneNew(data) {
      const res = await oneNew({
        key: sessionStorage.getItem("key"),
        id: parseInt(data),
      });
      if (res.data.key) {
        await sessionStorage.setItem("key", res.data.key);
        this.data = res.data.data;
        // 上下篇
        let newIdArray = JSON.parse(sessionStorage.getItem("newsIdArray"));
        for (let i = 0; i < newIdArray.length; i++) {
          if (data == newIdArray[i].id) {
            this.upname = newIdArray[i - 1];
            this.downname = newIdArray[i + 1];
          }
        }
      }
    },
    down(data){
       var keys = generatekey();
      var sign = encryptDes(JSON.stringify({newId:data}),keys)+'.html';
      
      const { href } = this.$router.resolve({
        path: `/newInfo/${sign}`
      });
      window.open(href, "_blank");
    }
  },
};
</script>

<style scoped lang="scss">
a{
  color: #777777;
  font-size: 0.9375rem /* 15px -> 0.9375rem */;

}
hr {
  height: 1px;
  background-color: #dddddd;
  border: none;
}
.index {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  .head {
    width: 74.375rem /* 1190px -> 74.375rem */;
    height: 3.125rem /* 50px -> 3.125rem */;
    font-size: 0.9375rem /* 15px -> 0.9375rem */;
    color: #333333;
    line-height: 3.125rem /* 50px -> 3.125rem */;
  }
  .content {
    width: 75rem /* 1200px -> 75rem */;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.25rem /* 68px -> 4.25rem */;
    .leftBox {
      .next {
        height: 5.625rem /* 90px -> 5.625rem */;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 2.9375rem /* 47px -> 2.9375rem */;
        .un {
          display: flex;
          font-size: 0.9375rem /* 15px -> 0.9375rem */;
          color: #444444;
          .boxun {
            width: 1.35625rem /* 21.7px -> 1.35625rem */;
            height: 1.35625rem /* 21.7px -> 1.35625rem */;
            border-radius: 50%;
            background-color: #dfdfdf;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.625rem /* 10px -> 0.625rem */;
            .comeun {
              width: 0.6875rem /* 11px -> 0.6875rem */;
              height: 0.375rem /* 6px -> 0.375rem */;
              transform: rotate(180deg);
            }
          }
        }
        .up {
          display: flex;
          font-size: 0.9375rem /* 15px -> 0.9375rem */;
          color: #444444;
          .boxun {
            width: 1.35625rem /* 21.7px -> 1.35625rem */;
            height: 1.35625rem /* 21.7px -> 1.35625rem */;
            border-radius: 50%;
            background-color: #dfdfdf;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.625rem /* 10px -> 0.625rem */;
            .comeup {
              width: 0.6875rem /* 11px -> 0.6875rem */;
              height: 0.375rem /* 6px -> 0.375rem */;
            }
          }
        }
      }
    }
    .left {
      width: 55.75rem /* 892px -> 55.75rem */;
      padding: 1.25rem /* 20px -> 1.25rem */;
      padding-bottom: 2.8125rem /* 45px -> 2.8125rem */;
      background-color: #fff;
      box-sizing: border-box;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.415625rem /* 22.65px -> 1.415625rem */;
        color: #333333;
        margin-bottom: 3.125rem /* 50px -> 3.125rem */;
      }
      .wear {
        text-indent: 2rem;
        padding: 1.25rem /* 20px -> 1.25rem */;
        font-size: 0.9375rem /* 15px -> 0.9375rem */;
        color: #444444;
      }
      .remarks {
        width: 37.5rem /* 600px -> 37.5rem */;
        font-size: 0.82125rem /* 13.14px -> 0.82125rem */;
        color: #888888;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 3.125rem /* 50px -> 3.125rem */;
        .remarksBox {
          display: flex;
          align-items: center;
        }
        .litterLogo {
          width: 2.0125rem /* 32.2px -> 2.0125rem */;
          height: 2.0125rem /* 32.2px -> 2.0125rem */;
          margin-right: 0.625rem /* 10px -> 0.625rem */;
        }
        .redian {
          width: 5.225rem /* 83.6px -> 5.225rem */;
          height: 1.7125rem /* 27.4px -> 1.7125rem */;
          border: 0.0625rem /* 1px -> 0.0625rem */ solid #e0e0e0;
          border-radius: 0.3125rem /* 5px -> 0.3125rem */;
          text-align: center;
          line-height: 1.7125rem /* 27.4px -> 1.7125rem */;
        }
      }
    }
    .right {
      width: 18.4375rem /* 295px -> 18.4375rem */;
    }
  }
}
</style>